import axios from 'axios';
import React, { useContext, useState, useEffect } from 'react';
// import { BrowserProvider } from 'ethers';
import { ethers } from 'ethers';
import { WalletContext } from './Common/WalletContext';
import { Link, useNavigate } from 'react-router-dom';
import Logo from '../assets/img/logo.png';


import Header from './Common/Header';
import Footer from './Common/Footer';

import SwapCSS from '../assets/css/Swap.css';
import StackCSS from '../assets/css/Stack.css';
import Token from '../assets/img/token.png';
import Phi from '../assets/img/phi.png';
import NewStackingBunny from '../assets/img/new_staking_bunny.png';
import VePHICake from '../assets/img/vephicake.png';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import WithdrawLogo from '../assets/img/withdraw_logo.png';

function Unstake() {
    const navigate = useNavigate();
    // const { connectWallet, unstakeAmount, phirate, usdtbalance, balance } = useContext(WalletContext);
    const { connectWallet, walletAddress, unstakeAmount, phirate, usdtbalance, balance } = useContext(WalletContext);
    const [usdtAmount, setUsdtAmount] = useState(''); // USDT amount to send
    const [homepageData, setHomepageData] = useState(null);
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
    const [phiAmount, setPHIAmount] = useState(0);    // State for PHI amount
    const [selectedToken2, setSelectedToken2] = useState('USDT');
    const [isLoading,setisLoading] = useState(false);
    const [totalDepositValue, setTotalDepositValue] = useState(null);
    const [Withdrawreport5, setWithdrawreport5] = useState(null);
    // const walletAddress = localStorage.getItem('walletAddress');
    // Declare state variables for the percentages
    const [fiftyPercent, setFiftyPercent] = useState(0);
    const [tenPercent, setTenPercent] = useState(0);
    const [fivePercent, setFivePercent] = useState(0);
    const [roiAmount, setRoiAmount] = useState(0);
    const [totalEarning, setTotalEarning] = useState(0);

    const handleConnectWallet = async () => {
        await connectWallet(); // Connect the wallet when button is clicked
    };

    const fetchHomepageData = async () => {
        try {
            const response = await axios.get(`${apiBaseUrl}/homepageapi/${walletAddress}`);
            // console.log(response.data);
            setHomepageData(response.data);
            setUsdtAmount(response.data?.wallet?.available_withdraw)
        } catch (error) {
            console.error('Error fetching homepage data:', error);
        }
    };

    const handleTransaction = async () => {
        // if (!window.ethereum || !walletAddress) {
        //     toast.error('Please connect your wallet first.');
        //     return;
        // }
        // const phi_amount = 45;
        // await unstakeAmount(phi_amount.toString());

        // setisLoading(true)
        // try {
        //     // Check if the user exists
        //     const response = await axios.get(`${apiBaseUrl}/getuser/${walletAddress}`);

        //     if (response.data) {
        //         if (homepageData?.wallet?.earning_wallet <= homepageData?.wallet?.available_withdraw) {
        //             toast.error('Please increase your earning limit.');
        //             return;
        //         }

        //         const withdraw_amount = usdtAmount * 0.105;
        //         const tranfer_amount = usdtAmount - withdraw_amount;
        //         const phi_amount = tranfer_amount/phirate;
        //         const phi_amount_string = phi_amount.toFixed(9);  // Limit to 9 decimals
        //         if(usdtAmount >= 1){
        //         // User exists, proceed with staking
        //         // const stakeResult = await unstakeAmount(phi_amount_string);
        //         const stakeResult = false;
        //         // console.log(usdtAmount);
        //         if (stakeResult) {
        //             // if (usdtAmount > balance) {
                   
        //             // Prepare form data for backend API submission
        //             const formDataToSend = {
        //                 amount: usdtAmount,
        //                 wallet_address: walletAddress,
        //             };

        //             try {
        //                 // Make the deposit request
        //                 const depositResponse = await axios.post(
        //                     `${apiBaseUrl}/payment_withdraw`,
        //                     formDataToSend,
        //                     {
        //                         headers: {
        //                             "Content-Type": "application/json",
        //                         },
        //                     }
        //                 );

        //                 // Handle the success response
        //                 toast.success('Staking Withdrawal successful!');
        //                 setisLoading(false);
        //                 fetchHomepageData();
                   
        //             } catch (depositError) {
        //                 console.error("Error during deposit:", depositError);
        //                 toast.error('Failed to make the Withdraw.');
        //                 setisLoading(false)

        //             }
        //         } else {
        //             toast.error('Transaction Failed');
        //             setisLoading(false)

        //         } }else{
        //             toast.error('USDT Amount should me grater than or equal to 1.');
        //             setisLoading(false)
        //         }
        //     } else {
        //         // User does not exist, navigate to the registration page
        //         navigate('/register', { state: { from: '/stack' } });
        //         setisLoading(false)


        //     }
        // } catch (error) {
        //     console.error('Error checking user:', error);
        //     toast.error('Failed to check user information.');
        //     setisLoading(false)

        // }
    };


    useEffect(() => {
        const userID = localStorage.getItem('walletAddress')

        if (walletAddress) {
            const fetchTotalDepositValu = async () => {
                try {
                    const response = await axios.get(`${apiBaseUrl}/total_withdraw/${walletAddress}`);
                    // const withdraw = await axios.get(`${apiBaseUrl}/withdraw_report5/${walletAddress}`);
                    // console.log(withdraw.data);
                    setTotalDepositValue(response.data);
                    // setWithdrawreport5(withdraw.data.data);
                } catch (error) {
                    console.error('Error fetching homepage data:', error);
                }
            };
            fetchHomepageData();
            fetchTotalDepositValu();
        }
    }, []);

    const totalDeposit = parseFloat(totalDepositValue || 0).toFixed(4);

    const truncateToFiveDigits = (num) => {
        const numStr = num.toString();
        if (numStr.includes('.')) {
            const [integerPart, decimalPart] = numStr.split('.');
            return `${integerPart}.${decimalPart.slice(0, 5)}`;
        }
        return numStr;
    };
    // Function to update sponsor earning and other appliances percentages dynamically
    useEffect(() => {
        const value = parseFloat(phiAmount);
        setFiftyPercent(value * 0.5);
        setTenPercent(value * 0.1);
        setFivePercent(value * 0.05);
        setTotalEarning(totalDeposit - value);

        // Calculate ROI amount based on the value range and corresponding percentage
        let roiPercentage = 0;
        if (value > 0 && value < 10) {
            roiPercentage = 0;  // 0% ROI
        } else if (value >= 10 && value < 100) {
            roiPercentage = 1;  // 1% ROI
        } else if (value >= 100 && value < 1000) {
            roiPercentage = 1.5;  // 1.5% ROI
        } else if (value >= 1000 && value < 10000) {
            roiPercentage = 2.5;  // 2.5% ROI
        } else if (value >= 10000 && value < 100000) {
            roiPercentage = 3;  // 3% ROI
        } else if (value >= 100000 && value < 1000000) {
            roiPercentage = 3.5;  // 3.5% ROI
        } else if (value >= 1000000 && value < 10000000) {
            roiPercentage = 4;  // 4% ROI
        } else if (value >= 10000000) {
            roiPercentage = 4.5;  // 4.5% ROI
        }

        // Calculate the ROI amount
        setRoiAmount(roiPercentage);
        // setRoiAmount((value * roiPercentage) / 100);
    }, [usdtAmount]);

    const handlePercentageClick = (percentage) => {
        const selectedBalance = selectedToken2 === 'PHI' ? balance : usdtbalance;
        const amount = selectedBalance * (percentage / 100); // No rounding

        if (selectedToken2 === 'PHI') {
            const usdtAmount = truncateToFiveDigits(amount);
            const phiAmount = truncateToFiveDigits(amount / phirate);
            setUsdtAmount(usdtAmount);
            setPHIAmount(phiAmount);
        } else {
            const phiAmount = truncateToFiveDigits(amount);
            const usdtAmount = truncateToFiveDigits(amount * phirate);
            setPHIAmount(phiAmount);
            setUsdtAmount(usdtAmount);
        }
    };

    // Handler for when the PHI input changes
    const handlePHIChange = (e) => {
        const enteredPHI = parseFloat(e.target.value) || 0;
        setPHIAmount(enteredPHI);
        const calculatedUSDT = truncateToFiveDigits(enteredPHI * phirate);
        setUsdtAmount(calculatedUSDT);
    };
    const availableWithdrawBlance = homepageData?.wallet?.available_withdraw || 0;
    const earning_wallet = homepageData?.wallet?.earning_wallet || 0;
    const availableWithdrawPHI = truncateToFiveDigits(availableWithdrawBlance / phirate);
    // console.log(availableWithdrawBlance);
    const getStatusStyle = (status) => {
        if (status === 'Completed') {
            return { backgroundColor: '#d1efff' };
        } else if (status === 'Rejected') {
            return { backgroundColor: '#fff2cb' };
        } else if (status === 'pending') {
            return { backgroundColor: '#ffdde0' };
        }
        return {};
    };

    return (
        < >
            <Header />

            <div style={{ backgroundColor: 'white', margin: '65px 0 0 0' }}>
                <ul className="nav-links" style={{ margin: '0', padding: '15px', alignItems: 'center', justifyContent: 'center', boxShadow: 'rgba(133, 133, 133, 0.1) 0px -2px 0px -8px inset' }}>
                    <li>Stacking</li>
                    <li>Syrup Pools</li>
                    <li>Position Manager</li>
                    <li>Liquid Stacking </li>
                    <li>Simple Stacking</li>
                    {/* <li>Trending Reward</li> */}
                </ul>
            </div >


            <div className="container lock-cake-container mb-5">
                <div className="card p-4 shadow-sm exact-layout-card">
                    <div className='d-flex justify-content-center'>
                        <div className='lock_phi_stake col-12 d-flex justify-content-center'>
                            <h3 className="" id='lock_phi' style={{ color: '#fff', fontWeight: '600' }}>Balance: ${parseFloat(availableWithdrawBlance).toFixed(4)}</h3>
                        </div>
                    </div>
                    {/* <h3 className="mb-4" id='lock_phi'>Unstake</h3> */}

                    <div className="row">
                        {/* Add CAKE Section */}
                        <div className="col-md-6 mb-3">
                            <h5 className="label-title">Withdraw PHI</h5>
                            <div className="input-group">
                                <span className="input-group-text">$</span>
                                <input
                                    type="number"
                                    className="form-control"
                                    placeholder="0"
                                    value={availableWithdrawBlance}
                                    // onChange={(e) => setUsdtAmount(e.target.value)}
                                    onChange={handlePHIChange}
                                    disabled
                                />
                                <span className="input-group-text">{availableWithdrawPHI} PHI</span>
                            </div>
                            {/* <div className='d-flex justify-content-between mt-2'>
                                <div className='percentage-selection' onClick={() => handlePercentageClick(25)}>25%</div>
                                <div className='percentage-selection' onClick={() => handlePercentageClick(50)}>50%</div>
                                <div className='percentage-selection' onClick={() => handlePercentageClick(75)}>75%</div>
                                <div className='percentage-selection' onClick={() => handlePercentageClick(100)}>Max</div>
                            </div> */}
                        </div>

                        {/* Add Duration Section */}
                        <div className="col-md-6 mb-3">
                            <h5 className="label-title">Withdrawal Capping</h5>
                            <div className="input-group">
                                <span className="input-group-text">$</span>
                                <input
                                    type="number"
                                    className="form-control"
                                    // placeholder={totalDeposit}
                                    value={earning_wallet}
                                    // onChange={(e) => setDuration(e.target.value)}
                                    disabled
                                    style={{
                                        backgroundColor: "white"
                                    }}
                                />
                                <span className="input-group-text">Remain Limit</span>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center row">
                        <div className="d-flex justify-content-center text-center col-md-6">
                            <button
                                className="btn connect-wallet-btn"
                                onClick={walletAddress ? handleTransaction : connectWallet} // Toggle between connect and transaction
                                disabled={isLoading}
                            >
                                {isLoading ? ('Withdrawing....') : (walletAddress ? 'Withdraw' : 'Connect Wallet')} {/* Change text based on wallet status */}
                            </button>
                        </div>
                    </div>
                    <center className='mt-3'>
                        <Link to="/withdraw_report">
                            Withdraw Report
                        </Link>
                    </center>
                    {/* {Withdrawreport5 && (
                        Withdrawreport5.map((report, index) => (
                            <div key={index} className="card mb-3 shadow-sm deposit-card" style={getStatusStyle(report.status)}>
                                <div className="card-body card_body_level d-flex align-items-center">
                                    <div className="flex-grow-1">
                                        <p><strong>Receiver ID :</strong> {report.sender}</p>
                                        <p><strong>Amount :</strong> ${parseFloat(report.amount).toFixed(5)}</p>
                                        <p><strong>Status :</strong> {report.status}</p>
                                    </div>
                                    <div className="deposit-icon d-flex flex-column align-items-center">
                                        <img src={WithdrawLogo} alt="Deposit Icon" />
                                        <div className="mt-2" style={{fontSize:'12px'}}>Date: {report.date}</div>
                                    </div>
    
                                </div>
                            </div>
                        ))
                    )

                    } */}
                    {/* <div>
                        <p>STAKE OVERVIEW</p>
                    </div>
                    <div className='d-flex justify-content-between lock_overview_card'>
                        <div className='d-flex' style={{ marginLeft: "20px" }}>
                            <img src={Logo} style={{ width: '35px', height: '35px', marginTop: '6px' }} alt="lock" />
                            <p style={{ fontWeight: '700', fontSize: '20px', color: "white", marginLeft: "15px", marginTop: '10px' }}>MY PHI YIELD</p>
                        </div>
                        <div>
                            <p style={{ fontWeight: '700', fontSize: '20px', color: "white", marginRight: "15px", marginTop: '7px' }}>${totalDeposit}</p>
                        </div>
                    </div> */}

                    {/* My veCAKE Section */}
                    {/* <div className="mt-4 lock-overview">
                        <div className="p-3 rounded">
                            <div className="row overview-stats">
                                <div className="col-7" style={{ textDecoration: 'underline dotted', textUnderlineOffset: '3px' }}>Promoter will gain</div>
                                <div className="col-5 text-end total_apr">50%</div>
                                <div className="col-5 text-end total_apr">{fiftyPercent} PHI</div>
                            </div>
                            <div className="row overview-stats">
                                <div className="col-7" style={{ textDecoration: 'underline dotted', textUnderlineOffset: '3px' }}>Primary Issuance</div>
                                <div className="col-5 text-end">10%</div>
                                <div className="col-5 text-end">{tenPercent} PHI</div>
                            </div>
                            <div className="row overview-stats">
                                <div className="col-6" style={{ textDecoration: 'underline dotted', textUnderlineOffset: '3px' }}>Ranking Allocation</div>
                                <div className="col-6 text-end">5%</div>
                                <div className="col-6 text-end">{fivePercent} PHI</div>
                            </div>
                            <div className="row overview-stats">
                                <div className="col-6" style={{ textDecoration: 'underline dotted', textUnderlineOffset: '3px' }}>Your Daily Yield</div>
                                <div className="col-6 text-end">{roiAmount}%</div>
                                <div className="col-6 text-end">{roiAmount} PHI</div>
                            </div>
                        </div>
                    </div> */}

                    {/* Connect Wallet Button */}
                    
                </div>
            </div >


            <Footer />
            <ToastContainer />
        </>
    );
}

export default Unstake;
