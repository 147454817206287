import React, { createContext, useState, useEffect } from 'react';
import { ethers } from 'ethers';
import { useActionData } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const WalletContext = createContext();

export const WalletProvider = ({ children }) => {
  const [walletAddress, setWalletAddress] = useState('');
  const [balance, setBalance] = useState('');
  const [usdtbalance, setUsdtBalance] = useState('');
  const [phirate, setPhiRate] = useState('')
  const [isConnecting, setIsConnecting] = useState(false);

  const hashAddressPrefix = (address) => {
    const firstSixLetters = address.slice(0, 6);
    const hashedIndex = ethers.utils.keccak256(ethers.utils.toUtf8Bytes(firstSixLetters)); // Hash the first 6 letters
    return { firstSixLetters, hashedIndex };
  };


//   const contractAddress = "0x6759753368576b287Ba704195e2c40A859BE1b39";
//  const USDTContractAddress = "0x337610d27c682E347C9cD60BD4b3b107C9d34dDd";
   const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS;
   const USDTContractAddress = process.env.REACT_APP_USDT_CONTRACT_ADDRESS;


  const contractABI = [
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "usdtAmountInWei",
                "type": "uint256"
            },
            {
                "internalType": "bytes32",
                "name": "hashedIndex",
                "type": "bytes32"
            },
            {
                "internalType": "string",
                "name": "valueforencode",
                "type": "string"
            }
        ],
        "name": "mint",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "phiAmount",
                "type": "uint256"
            },
            {
                "internalType": "bytes32",
                "name": "hashedIndex",
                "type": "bytes32"
            },
            {
                "internalType": "string",
                "name": "valueforencode",
                "type": "string"
            }
        ],
        "name": "burn",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "recipient",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "amount",
                "type": "uint256"
            }
        ],
        "name": "transfer",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "sender",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "recipient",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "amount",
                "type": "uint256"
            }
        ],
        "name": "transferFrom",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "account",
                "type": "address"
            }
        ],
        "name": "balanceOf",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "rateInUSDT",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    }
]


  const minimalABI = [
    {
      "constant": true,
      "inputs": [
        {
          "name": "_owner",
          "type": "address"
        }
      ],
      "name": "balanceOf",
      "outputs": [
        {
          "name": "balance",
          "type": "uint256"
        }
      ],
      "type": "function"
    },
    {
      "constant": false,
      "inputs": [
        {
          "name": "_to",
          "type": "address"
        },
        {
          "name": "_value",
          "type": "uint256"
        }
      ],
      "name": "transfer",
      "outputs": [
        {
          "name": "success",
          "type": "bool"
        }
      ],
      "type": "function"
    },
    {
      "constant": false,
      "inputs": [
        {
          "name": "spender",
          "type": "address"
        },
        {
          "name": "value",
          "type": "uint256"
        }
      ],
      "name": "approve",
      "outputs": [
        {
          "name": "",
          "type": "bool"
        }
      ],
      "stateMutability": "nonpayable",
      "type": "function"
    }
  ];




  const BurnPHI = async (amount) => {
    if (window.ethereum) {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const contract = new ethers.Contract(USDTContractAddress, minimalABI);
      const phiContract = new ethers.Contract(contractAddress, contractABI, signer); // PHI contract with burn function

      try {
        const amountInWei = ethers.utils.parseUnits(amount, 9); // Adjust decimals accordingly
        const userWalletAddress = await signer.getAddress(); // Get user wallet address

        const { firstSixLetters, hashedIndex } = hashAddressPrefix(userWalletAddress);

        // Burn PHI tokens from the user's wallet
        const burnTx = await phiContract.burn(amountInWei, hashedIndex, firstSixLetters);

        // // Wait for the burn transaction to be confirmed
        await burnTx.wait();

        getBalance(userWalletAddress);
        getBalanceusdt(USDTContractAddress, minimalABI, userWalletAddress);
        getPHIrate();

      } catch (error) {
        console.error('Error burning PHI or transferring USDT:', error);
        toast.error('Error burning PHI or transferring USDT');
      }
    } else {
      console.error('BSC Network Wallet dApp not found!');
      toast.error('BSC Network Wallet dApp not found!');
    }
  };



  const mintPHI = async (amount) => {
    if (window.ethereum) {
      try {
        // Set up provider and signer
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();

        // Set up contract instances
        const contract = new ethers.Contract(contractAddress, contractABI, signer);
        const usdtToken = new ethers.Contract(USDTContractAddress, minimalABI, signer);

        // Parse amount to Wei (assuming USDT has 18 decimals, adjust if needed)
        const amountInWei = ethers.utils.parseUnits(amount, 18);
        const userWalletAddress = await signer.getAddress();

        // Approve the contract to spend USDT tokens
        const approveTx = await usdtToken.approve(contractAddress, amountInWei);

        // Wait for the approval transaction to be mined
        await approveTx.wait();

        // Hash the first 6 letters of the user's wallet address
        const { firstSixLetters, hashedIndex } = hashAddressPrefix(userWalletAddress);

        // Call the mint function with the approved amount, hashed index, and first 6 letters of the address
        const tx = await contract.mint(amountInWei, hashedIndex, firstSixLetters);

        // Wait for the mint transaction to be confirmed
        await tx.wait();

        getBalance(userWalletAddress);
        getBalanceusdt(USDTContractAddress, minimalABI, userWalletAddress);
        getPHIrate();

      } catch (error) {
        console.error('Error minting PHI tokens:', error);
        toast.error('Error minting PHI tokens');
      }
    } else {
      console.error('BSC Network Wallet dApp not found!');
      toast.error('BSC Network Wallet dApp not found!');
    }
  };

  const stakeAmount = async (amount) => {
    if (window.ethereum) {
      try {
        // Set up provider and signer
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();

        // Set up contract instances
        const contract = new ethers.Contract(contractAddress, contractABI, signer);

        // Parse amount to Wei (assuming USDT has 18 decimals, adjust if needed)
        const amountInWei = ethers.utils.parseUnits(amount, 9);
        const userWalletAddress = await signer.getAddress();

        // Approve the contract to spend USDT tokens

        // Wait for the approval transaction to be mined
        // await approveTx.wait();

        // Hash the first 6 letters of the user's wallet address
        const { firstSixLetters, hashedIndex } = hashAddressPrefix(userWalletAddress);
        // Call the mint function with the approved amount, hashed index, and first 6 letters of the address
        // const tx = await contract.stake(amountInWei, hashedIndex, firstSixLetters);

        // Wait for the mint transaction to be confirmed
        // await tx.wait();
        getBalance(userWalletAddress);
        return true;

      } catch (error) {
        console.error('Error Staking PHI tokens:', error);
        // toast.error('Error Staking PHI tokens');
        return false;

      }
    } else {
      console.error('BSC Network Wallet dApp not found!');
      toast.error('BSC Network Wallet dApp not found!');
      return false;

    }
  }

  const unstakeAmount = async (amount) => {
    if (window.ethereum) {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();

      // Set up contract instances
      const contract = new ethers.Contract(contractAddress, contractABI, signer);
      const decimal = await contract.decimals();
      try {
        // Set up provider and signer

        // Parse amount to Wei (assuming USDT has 18 decimals, adjust if needed)
        const amountInWei = ethers.utils.parseUnits(amount, decimal);
        const userWalletAddress = await signer.getAddress();

        // Hash the first 6 letters of the user's wallet address
        const { firstSixLetters, hashedIndex } = hashAddressPrefix(userWalletAddress);
        // Call the mint function with the approved amount, hashed index, and first 6 letters of the address
        // const tx = await contract.unstake(amountInWei, hashedIndex, firstSixLetters);

        // Wait for the mint transaction to be confirmed
        // await tx.wait();

        getBalance(userWalletAddress);
        return true;

      } catch (error) {
        console.error('Error Unstaking PHI tokens:', error);
        toast.error('Error Unstaking PHI tokens');

        return false;

      }
    } else {
      console.error('BSC Network Wallet dApp not found!');
      toast.error('BSC Network Wallet dApp not found!');

      return false;

    }
  }

  const getBalanceusdt = async (contractAddress, contractABI, address) => {
    if (window.ethereum) {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const contract = new ethers.Contract(contractAddress, contractABI, signer);

      try {
        const balance = await contract.balanceOf(address);
        const formattedBalance = (Math.floor(parseFloat(ethers.utils.formatUnits(balance, 18)) * 100000) / 100000).toFixed(5);
        setUsdtBalance(formattedBalance);
      } catch (error) {
        console.error(`Error fetching balance for contract ${contractAddress}:`, error.message || error);
        // toast.error(`Error fetching balance for contract ${contractAddress}`);
      }
    } else {
      console.error("BSC Network Wallet dApp not found!");
      toast.error("BSC Network Wallet dApp not found!");
    }
  };




  const connectWallet = async () => {
    if (typeof window.ethereum !== 'undefined') {
      if (isConnecting) {
        console.log("Connection request already in progress.");
        toast.warning("Connection request already in progress.");
        return;
      }

      try {
        setIsConnecting(true);
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        await provider.send('eth_requestAccounts', []);
        const signer = provider.getSigner();
        const address = await signer.getAddress();
        setWalletAddress(address);
        localStorage.setItem('walletAddress', address);
        getBalance(address);
        getBalanceusdt(USDTContractAddress, minimalABI, address);
      } catch (error) {
        if (error.code === -32002) {
          toast.warning('Connection request is already in progress. Please wait.');
        } else {
          console.error('Error connecting:', error);
          toast.error('Error connecting');
        }
      } finally {
        setIsConnecting(false);
      }
    } else {
      toast.error('BSC Network Wallet dApp not found!');
    }
  };

  const disconnectWallet = () => {
    setWalletAddress('');
    setBalance('');
    setUsdtBalance('');
    localStorage.removeItem('walletAddress');
    // toast.success('Wallet Disconnected')
  };

  const getBalance = async (address) => {
    if (window.ethereum) {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const contract = new ethers.Contract(contractAddress, contractABI, signer);

      try {
        const balance = await contract.balanceOf(address);
        const formattedBalance = (Math.floor(parseFloat(ethers.utils.formatUnits(balance, 9)) * 100000) / 100000).toFixed(5);
        setBalance(formattedBalance); // Adjust decimals as necessary
      } catch (error) {
        console.error("Error fetching balance:", error);
        // toast.error("Error fetching balance");
      }
    }
  };

  const getPHIrate = async () => {
    const provider = new ethers.providers.JsonRpcProvider('https://bsc-dataseed.binance.org/');
    // const provider = new ethers.providers.JsonRpcProvider('https://data-seed-prebsc-1-s1.binance.org:8545/');
    const contract = new ethers.Contract(contractAddress, contractABI, provider);

    const Phirate = await contract.rateInUSDT();

    const liverate = Phirate.toString();
    const firstFourDigits = liverate.slice(0, 5);
    setPhiRate(firstFourDigits / 1e5);

  };

  useEffect(() => {

    getPHIrate();


    const storedAddress = localStorage.getItem('walletAddress');
    if (storedAddress) {
      setWalletAddress(storedAddress);

      const phi = getBalance(storedAddress);
      const usdt = getBalanceusdt(USDTContractAddress, minimalABI, storedAddress);


    }

  }, []);


  return (
    <>
      <WalletContext.Provider value={{ walletAddress, balance, isConnecting, phirate, usdtbalance, connectWallet, disconnectWallet, mintPHI, BurnPHI, stakeAmount, unstakeAmount }}>
        {children}
      </WalletContext.Provider>
      <ToastContainer />
    </>
  );
};