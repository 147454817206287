import axios from 'axios';
import React, { useContext, useState, useEffect } from 'react';
// import { BrowserProvider } from 'ethers';
import { ethers } from 'ethers';
import { useNavigate } from 'react-router-dom';
import { WalletContext } from './Common/WalletContext';
import Logo from '../assets/img/logo.png';
import phiNameLogoFull from '../assets/img/phi_name_logo.png';

import Header from '../Views/Common/Header';
import Footer from '../Views/Common/Footer';

import SwapCSS from '../assets/css/Swap.css';
import StackCSS from '../assets/css/Stack.css';
import Token from '../assets/img/token.png';
import Phi from '../assets/img/phi.png';
import NewStackingBunny from '../assets/img/new_staking_bunny.png';
import VePHICake from '../assets/img/vephicake.png';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Stack() {
    // const { connectWallet, walletAddress, transferUSDT, usdtAddress } = useContext(WalletContext);

    const { Web3Provider } = ethers.providers;
    const { connectWallet, walletAddress, stakeAmount, phirate, usdtbalance, balance } = useContext(WalletContext);
    const [usdtAmount, setUsdtAmount] = useState(0); // USDT amount to send
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
    const [phiAmount, setPHIAmount] = useState(0);    // State for PHI amount
    const [selectedToken2, setSelectedToken2] = useState('USDT');

    const [totalDepositValue, setTotalDepositValue] = useState(null);

    // Declare state variables for the percentages
    const [fiftyPercent, setFiftyPercent] = useState(0);
    const [tenPercent, setTenPercent] = useState(0);
    const [fivePercent, setFivePercent] = useState(0);
    const [roiAmount, setRoiAmount] = useState(0);
    const [totalEarning, setTotalEarning] = useState(0);

    const navigate = useNavigate();


    // Function to update sponsor earning and other appliances percentages dynamically
    useEffect(() => {
        const value = parseFloat(usdtAmount);  // Convert input to a number
        setFiftyPercent(value * 0.5);  // Calculate 50% of the input
        setTenPercent(value * 0.1);    // Calculate 10% of the input
        setFivePercent(value * 0.05);    // Calculate 10% of the input
        setTotalEarning(value * 2);    // Calculate x2 of the input

        // Calculate ROI amount based on the value range and corresponding percentage
        let roiPercentage = 0;
        if (value > 0 && value < 10) {
            roiPercentage = 0;  // 0% ROI
        } else if (value >= 10 && value < 100) {
            roiPercentage = 1;  // 1% ROI
        } else if (value >= 100 && value < 1000) {
            roiPercentage = 1.5;  // 1.5% ROI
        } else if (value >= 1000 && value < 10000) {
            roiPercentage = 2.5;  // 2.5% ROI
        } else if (value >= 10000 && value < 100000) {
            roiPercentage = 3;  // 3% ROI
        } else if (value >= 100000 && value < 1000000) {
            roiPercentage = 3.5;  // 3.5% ROI
        } else if (value >= 1000000 && value < 10000000) {
            roiPercentage = 4;  // 4% ROI
        } else if (value >= 10000000) {
            roiPercentage = 5;  // 4.5% ROI
        }

        // Calculate the ROI amount
        // setRoiAmount((value * roiPercentage) / 100);
        setRoiAmount(roiPercentage);
    }, [usdtAmount]);

    const handleConnectWallet = async () => {
        await connectWallet(); // Connect the wallet when button is clicked
    };


    const [duration, setDuration] = useState(26);
    // console.log(walletAddress);
    const handleTransaction = async () => {
        if (!window.ethereum || !walletAddress) {
            toast.error('Please connect your wallet first.');
            return;
        }

        try {
            // Check if the user exists
            // const response = await axios.get(`${apiBaseUrl}/getuser/${walletAddress}`);
           
            // if (response.data) {
                // User exists, proceed with staking
                // console.log(phiAmount, balance);
                // if (phiAmount <= balance) {
                //     // Prepare form data for backend API submission
                //     const stakeResult = await stakeAmount(phiAmount);

                //     if (stakeResult) {

                //         const formDataToSend = {
                //             amount: usdtAmount,
                //             wallet_address: walletAddress,
                //         };
                //         // console.log(usdtAmount);
                //         try {
                //             // Make the deposit request
                //             // const depositResponse = await axios.post(
                //             //     `${apiBaseUrl}/make_deposite`,
                //             //     formDataToSend,
                //             //     {
                //             //         headers: {
                //             //             "Content-Type": "application/json",
                //             //         },
                //             //     }
                //             // );

                //             // Handle the success response
                //             toast.success('Staking was successful!');
                //         } catch (depositError) {
                //             console.error("Error during deposit:", depositError);
                //             toast.error('Failed to make the deposit.');
                //         }
                //     } else {
                //         toast.warning('You Denied the transaction')
                //     }
                // } else {
                //     console.log('Insufficient PHI Balance in Wallet.')
                //     if (usdtAmount < 10) {
                //         toast.error('Invalid Amount. Phi Amount should be greater than $10.');
                //     } else {
                //         toast.error('Insufficient PHI Balance in Wallet.');
                //     }
                // }
            // } else {
            //     // User does not exist, navigate to the registration page
            //     navigate('/register', { state: { from: '/stack' } });

            // }
        } catch (error) {
            console.error('Error checking user:', error);
            toast.error('Failed to check user information.');
        }
    };

    useEffect(() => {
        const userID = localStorage.getItem('user_ID')

        if (userID) {
            const fetchTotalDepositValu = async () => {
                try {
                    const response = await axios.get(`${apiBaseUrl}/total_withdraw/${userID}`);

                    // console.log(response.data);
                    setTotalDepositValue(response.data);
                } catch (error) {
                    console.error('Error fetching homepage data:', error);
                }
            };

            fetchTotalDepositValu();
        }
    }, []);

    const totalDeposit = parseFloat(totalDepositValue || 0).toFixed(4);

    const truncateToFiveDigits = (num) => {
        const numStr = num.toString();
        if (numStr.includes('.')) {
            const [integerPart, decimalPart] = numStr.split('.');
            return `${integerPart}.${decimalPart.slice(0, 5)}`;
        }
        return numStr; // If no decimal part, return the integer part as is
    };
    const handlePercentageClick = (percentage) => {
        const selectedBalance = balance;
        const amount = selectedBalance * (percentage / 100); // No rounding

 
            const phiAmount = truncateToFiveDigits(amount);
            const usdtAmount = truncateToFiveDigits(amount * phirate);
            setPHIAmount(phiAmount);
            setUsdtAmount(usdtAmount);
        
    };

    // Handler for when the PHI input changes
    const handlePHIChange = (e) => {
        const enteredPHI = parseFloat(e.target.value) || 0;
        setUsdtAmount(enteredPHI);

        const calculatedUSDT = truncateToFiveDigits(enteredPHI / phirate);
        setPHIAmount(calculatedUSDT.toString());

    };

    const copyToClipboard = () => {
        const address = `https://phinetwork.io/register/${walletAddress}`;
        navigator.clipboard.writeText(address).then(() => {
            toast.success("Address copied!");
        }).catch((err) => {
            console.error('Failed to copy: ', err);
            toast.error('Failed to copy');
        });
    };


    return (
        < >
            <Header />

            <div style={{ backgroundColor: 'white', margin: '25px 0 0 0' }}>
                <ul className="nav-links" style={{ margin: '0', padding: '15px', alignItems: 'center', justifyContent: 'center', boxShadow: 'rgba(133, 133, 133, 0.1) 0px -2px 0px -8px inset' }}>
                    <li>Stacking</li>
                    <li>Syrup Pools</li>
                    <li>Position Manager</li>
                    <li>Liquid Stacking </li>
                    <li>Simple Stacking</li>
                </ul>
            </div >
            <div className="cake-staking-container">
                <div className="container py-3 d-flex justify-content-between align-items-center flex-row">
                    {/* Left Section */}
                    <div className="text-section text-left">
                        <h1 className="staking-title">PHI Staking</h1>
                        <p className="staking-description">
                            Enjoy the benefits of every block PHI yield, revenue share, gauges voting, farm yield boosting, participating in IFOs, and so much more!
                        </p>
                        <a href="#" className="btn btn-link get-cake-link">
                            Get PHI →
                        </a>
                    </div>

                    {/* Right Section */}
                    <div className="new-to-staking text-center mt-4 mt-md-0">
                        {/* Add your image here */}
                        <img
                            src={NewStackingBunny}
                            alt="PHI Staking"
                            className="staking-image mb-3 mb-md-0"
                        />
                        <button className="btn new-staking-btn">
                            New to PHI Staking?
                        </button>
                    </div>
                </div>
            </div>


            <div className="container lock-cake-container">
                <div className="card p-4 shadow-sm exact-layout-card">
                    <div className='d-flex justify-content-center'>
                        <div className='lock_phi_stake col-12 d-flex justify-content-center'>
                            <h3 className="" id='lock_phi' style={{ color: '#fff', fontWeight: '600', margin: 'auto' }}>Stake PHI</h3>
                        </div>
                    </div>

                    <div className="row">
                        {/* Add CAKE Section */}
                        <div className="col-md-6 mb-3">
                            <h5 className="label-title">Enter amount in USDT</h5>
                            <div className="input-group">
                                <span className="input-group-text">$</span>
                                <input
                                    type="number"
                                    className="form-control"
                                    placeholder="0"
                                    value={usdtAmount || null}

                                    // onChange={(e) => setUsdtAmount(e.target.value)}
                                    onChange={handlePHIChange}
                                />
                                <span className="input-group-text"> {phiAmount}{' '} <img src={phiNameLogoFull} style={{ width: '40px', margin: '0px 2px 1px 5px' }} alt="logo" /></span>
                            </div>
                            <div className='d-flex justify-content-between mt-2'>
                                <div className='percentage-selection' onClick={() => handlePercentageClick(25)}>25%</div>
                                <div className='percentage-selection' onClick={() => handlePercentageClick(50)}>50%</div>
                                <div className='percentage-selection' onClick={() => handlePercentageClick(75)}>75%</div>
                                <div className='percentage-selection' onClick={() => handlePercentageClick(100)}>Max</div>
                            </div>
                        </div>

                        {/* Add Duration Section */}
                        <div className="col-md-6 mb-3">
                            <h5 className="label-title">Earning Potential</h5>
                            <div className="input-group">
                                <span className="input-group-text">$</span>
                                <input
                                    type="number"
                                    className="form-control"
                                    // placeholder="26"
                                    value={totalEarning}
                                    // onChange={(e) => setDuration(e.target.value)}
                                    disabled
                                    style={{
                                        backgroundColor: "white"
                                    }}
                                />
                                <span className="input-group-text">Earning</span>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>STAKE OVERVIEW</div>
                    </div>
                    {/* <div className='d-flex justify-content-between lock_overview_card'>
                        <div className='d-flex' style={{ marginLeft: "20px" }}>
                            <img src={Logo} style={{ width: '35px', height: '35px', marginTop: '6px' }} alt="lock" />
                            <p style={{ fontWeight: '700', fontSize: '20px', color: "white", marginLeft: "15px", marginTop: '10px' }}>MY PHI YIELD</p>
                        </div>
                        <div>
                            <p style={{ fontWeight: '700', fontSize: '20px', color: "white", marginRight: "15px", marginTop: '7px' }}>${totalDeposit}</p>
                        </div>
                    </div> */}

                    {/* My veCAKE Section */}
                    <div className="lock-overview">
                        <div className="p-3 rounded">
                            <div className="row overview-stats">
                                <div className="col-7" style={{ textDecoration: 'underline dotted', textUnderlineOffset: '3px' }}>Promoter will gain</div>
                                <div className="col-5 text-end total_apr">50%</div>
                                {/* <div className="col-5 text-end total_apr">{fiftyPercent} PHI</div> */}
                            </div>
                            <div className="row overview-stats">
                                <div className="col-7" style={{ textDecoration: 'underline dotted', textUnderlineOffset: '3px' }}>Primary Issuance</div>
                                <div className="col-5 text-end">10%</div>
                                {/* <div className="col-5 text-end">{tenPercent} PHI</div> */}
                            </div>
                            <div className="row overview-stats">
                                <div className="col-6" style={{ textDecoration: 'underline dotted', textUnderlineOffset: '3px' }}>Ranking Allocation</div>
                                <div className="col-6 text-end">5%</div>
                                {/* <div className="col-6 text-end">{fivePercent} PHI</div> */}
                            </div>
                            <div className="row overview-stats">
                                <div className="col-6" style={{ textDecoration: 'underline dotted', textUnderlineOffset: '3px' }}>Your Daily Yield</div>
                                <div className="col-6 text-end">{roiAmount}%</div>
                                {/* <div className="col-6 text-end">{roiAmount} PHI</div> */}
                            </div>
                        </div>
                    </div>

                    {/* Connect Wallet Button */}
                    <div className="d-flex justify-content-center row">
                        <div className="d-flex justify-content-center text-center mt-4 col-md-6">
                            <button
                                className="btn stake-connect-wallet-btn"
                                onClick={walletAddress ? handleTransaction : connectWallet} // Toggle between connect and transaction
                            >
                                {walletAddress ? 'Stake' : 'Connect Wallet'} {/* Change text based on wallet status */}
                            </button>
                        </div>
                    </div>
                </div>
            </div >
            <div className="help-section" style={{margin:'0 15px'}}>
                        <h4 style={{ color: 'black', fontWeight: '600' }}>Refferal Link</h4>
                        <p style={{ fontSize: '14px', backgroundColor: '#eef9ff', border: '1px solid white', padding: '5px', borderRadius: ' 7px', color: 'black',wordWrap:'break-word', wordBreak:'break-word',textAlign:'center'}}> https://phinetwork.io/register/{walletAddress} </p>
                        <button className="btn shadow need-help-btn" id='need-help-btns' onClick={copyToClipboard}>Copy</button>
                        {/* <img src={Help} alt="Bunny" className="bunny-img" /> */}
                    </div>
            <div className="container">
                <section className="mt-3">
                    {/* <h2 className='mb-4' style={{ color: '#7645d9', fontWeight: '700', lineHeight: '1.1', fontSize: '32px' }}>Benefits of PHI</h2> */}
                    <div className="row" style={{ display: 'flex', justifyContent: 'center' }}>
                        <div className="col-md-4 mb-4 d-flex">
                            <div className="card shadow flex-fill">
                                <div className="card-body">
                                    <h5 className="card-title" style={{ color: '#7645d9', fontWeight: '700', lineHeight: '1.1', fontSize: '22px' }}>Benefits of PHI</h5>
                                    <ul>
                                        <li>Phi Crypto tokens are like digital keys that work on
                                            specific digital ledgers.
                                            Phi Token has no maximum
                                            supply, meaning minters can create and indefinitely
                                            extract this cryptocurrency.
                                            This is called an inflationary cryptocurrency, one that
                                            continuously inflates the supply.</li>
                                    </ul>
                                    <a href="#" className="btn btn-info card_bottons">Check Reward</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 mb-4 d-flex">
                            <div className="card shadow flex-fill">
                                <div className="card-body">
                                    <h5 className="card-title" style={{ color: '#7645d9', fontWeight: '700', lineHeight: '1.1', fontSize: '22px' }}>Phi Algorithm
                                    </h5>
                                    <ul>
                                        <li>When a user swap to Buys a Phi Token, the Phi token
                                            will be generated automatically and when the user Swap to
                                            sells a Phi Token, the sold Phi tokens will be Burned. ​</li>
                                        <li>The Tokens of 95% will be generated of the amount
                                            Swapped by the user for the buy of tokens.​</li>
                                    </ul>
                                    <a href="#" className="btn btn-info card_bottons">Check Rewards</a>
                                </div>
                            </div>
                        </div>
                    </div>

                </section >

                <section className="mt-1">
                    <div className="row " style={{ display: 'flex', justifyContent: 'center' }}>
                        <div className="col-md-4 mb-4">
                            <div className="card shadow flex-fill">
                                <div className="card-body">
                                    <h5 className="card-title" style={{ color: '#7645d9', fontWeight: '700', lineHeight: '1.1', fontSize: '22px' }}>Probability</h5>
                                    <p>PHI emphasizes the importance of market makers in the cryptocurrency space, who can earn profits by facilitating trades​</p>
                                    <a href="#" className="btn cards_botton_outline mt-4">Check</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 mb-4">
                            <div className="card shadow flex-fill">
                                <div className="card-body">
                                    <h5 className="card-title" style={{ color: '#7645d9', fontWeight: '700', lineHeight: '1.1', fontSize: '22px' }}>Eligibility</h5>
                                    <p>There are no strict educational requirements for joining the network, making it an inclusive opportunity for various demographics</p>
                                    <a href="#" className="btn cards_botton_outline" >Check</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row" style={{ display: 'flex', justifyContent: 'center' }}>
                        <div className="col-md-4 mb-4">
                            <div className="card shadow flex-fill">
                                <div className="card-body">
                                    <h5 className="card-title" style={{ color: '#7645d9', fontWeight: '700', lineHeight: '1.1', fontSize: '22px' }}>Team Staking & Redeem Bonuses</h5>
                                    <p>Additional bonuses can be earned based on the staking and withdrawals of team members across 21 levels, encouraging team growth and collaboration​</p>
                                    <a href="#" className="btn cards_botton_outline">Check</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 mb-4">
                            <div className="card shadow flex-fill">
                                <div className="card-body">
                                    <h5 className="card-title" style={{ color: '#7645d9', fontWeight: '700', lineHeight: '1.1', fontSize: '22px' }}>Structured Growth</h5>
                                    <p>The staking packages offer daily growth percentages ranging from 1% to 5%, depending on the investment tier</p>
                                    <a href="#" className="btn cards_botton_outline mt-5">Learn More</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div >

            <Footer />
            <ToastContainer />
        </>
    );
}

export default Stack;
