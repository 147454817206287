import axios from 'axios';
import React, { useState, useEffect, useContext, useCallback, useRef } from 'react';
import '../../../assets/css/Report.css';
import Header from '../../Common/Header';
import Footer from '../../Common/Footer';
import { useNavigate } from 'react-router-dom';
import { WalletContext } from '../../Common/WalletContext';

const RedeemReport = () => {
    const { walletAddress } = useContext(WalletContext);
    const [userID, setUserID] = useState(null);
    const [depositReport, setDepositReport] = useState([]);
    const [page, setPage] = useState(1);
    const [limit] = useState(10);
    const [hasMore, setHasMore] = useState(true);
    const [loading, setLoading] = useState(false);
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
    const navigate = useNavigate();
    const loadingRef = useRef(false);

    const checkWalletAndFetchData = useCallback(async (walletAddress) => {
        if (!walletAddress) {
            console.log('Wallet not connected. Waiting...');
            return;
        }

        try {
            const response = await axios.get(`${apiBaseUrl}/getuser/${walletAddress}`);
            const userData = response.data;
            const userID = userData?.id;

            if (!userID) {
                console.log('User not found, navigating to register.');
                navigate('/register');
            } else {
                localStorage.setItem('user_ID', userID);
                setUserID(userID);
            }
        } catch (error) {
            console.error('Error fetching user data:', error);
        }
    }, [apiBaseUrl, navigate]);

    const fetchRedeemReport = useCallback(async (walletAddress, currentPage) => {
        if (loadingRef.current || !hasMore) return;

        loadingRef.current = true;
        setLoading(true);
        try {
            const response = await axios.get(`${apiBaseUrl}/Transaction/${walletAddress}/redeem_bonus`, {
                params: {
                    page: currentPage,
                    limit: limit,
                }
            });

            const fetchedData = response.data.transaction.data;

            if (fetchedData.length > 0) {
                setDepositReport(prevReports => {
                    const newReports = [...prevReports];
                    fetchedData.forEach(newItem => {
                        if (!newReports.some(existingItem => existingItem.id === newItem.id)) {
                            newReports.push(newItem);
                        }
                    });
                    return newReports;
                });
                setPage(prevPage => prevPage + 1);
            } else {
                setHasMore(false);
            }
        } catch (error) {
            console.error('Error fetching redeem report data:', error);
            setHasMore(false);
        } finally {
            setLoading(false);
            loadingRef.current = false;
        }
    }, [apiBaseUrl, limit, hasMore]);

    useEffect(() => {
        if (walletAddress) {
            checkWalletAndFetchData(walletAddress);
            fetchRedeemReport(walletAddress, 1);
        }
    }, [walletAddress, checkWalletAndFetchData, fetchRedeemReport]);

    useEffect(() => {
        const handleScroll = () => {
            if (
                window.innerHeight + document.documentElement.scrollTop >=
                document.documentElement.offsetHeight - 100
            ) {
                if (hasMore && !loadingRef.current) {
                    fetchRedeemReport(walletAddress, page);
                }
            }
        };

        const debouncedHandleScroll = debounce(handleScroll, 200);

        window.addEventListener('scroll', debouncedHandleScroll);
        return () => window.removeEventListener('scroll', debouncedHandleScroll);
    }, [walletAddress, page, hasMore, fetchRedeemReport]);

    const formatDate = (dateString) => {
        const months = [
            'Jan', 'Feb', 'March', 'April', 'May', 'June',
            'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'
        ];

        const date = new Date(dateString);
        const day = date.getDate();
        const month = months[date.getMonth()];
        const year = date.getFullYear();

        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const seconds = date.getSeconds().toString().padStart(2, '0');

        return `${day} ${month} ${year} | ${hours}:${minutes}:${seconds}`;
    };

    // Debounce function
    function debounce(func, wait) {
        let timeout;
        return function executedFunction(...args) {
            const later = () => {
                clearTimeout(timeout);
                func(...args);
            };
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
        };
    }
    return (
        <>
            <Header setUserID={setUserID} />
            <div className="container main_container_height" style={{marginTop:'65px'}}>
                <h2 className="text-center mb-4 network_heading">Redeem Bonus History</h2>
                {depositReport ? (
                    depositReport.map((report, index) => (
                        <div key={index} >
                        {report.reciver === walletAddress &&

                            <div className="team-transaction-box shadow">
                                <div className="flex-grow-1">
                                    <div className="team-transaction-name-2 mb-2">{report.sender}</div>
                                    {/* <div className='d-flex justify-content-between'>
                                        <small className=""><strong>Date:{' '}</strong> {formatDate(report.created_at)}</small>
                                        <div><strong>Value :</strong> ${report.amount}</div>
                                        </div> */}
                                    <div className='d-flex justify-content-between'>
                                        <div>
                                        <div style={{ display: 'flex', alignItems: 'center' }}><span className="report-date" style={{ fontSize: '12px', color: '#0d6efd' }}><strong>Level :</strong> {report.level}</span><br /></div>
                                        <div style={{ display: 'flex', alignItems: 'center',marginTop:'-8px' }}><span className="report-date" style={{ fontSize: '12px', color: '#0d6efd' }}>{formatDate(report.created_at)}</span><br /></div>
                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'center' }}><h6 className='' style={{color:'#0d6efd'}}>${parseFloat(report.amount).toFixed(10)}</h6></div>
                                        {/* <div style={{ display: 'flex', alignItems: 'center' }}><h6 className='transaction-btn2'>Level {report.level}</h6></div> */}
                                    </div>
                                    {/* <p><strong>Stake :</strong> {report.status}</p> */}

                                </div>
                                <div className="deposit-icon d-flex flex-column align-items-center">
                                    {/* <img src={DepositeLogo} alt="Deposit Icon" /> */}
                                </div>
                            </div>
                        }

                    </div>
                    ))
                ) : (
                    <p className="text-center">No Income History Available</p>
                )}
            </div>
            <Footer />
        </>
    );
};

export default RedeemReport;
